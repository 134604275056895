const styles = {
  root: (theme) => ({
    width: `min(100%, var(--content-M-max-width))`,
    mx: 'auto',

    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',
  }),
  buttonsContainer: (theme) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
    },
  }),
}

export default styles
